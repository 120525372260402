import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Navigation from "./navigation/Navigation";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import icon from "../../assets/images/bucks-n-balloons-typeface.svg";
import { Link } from "react-router-dom";
import HideOnScroll from "../effect/HideOnScroll";
import ImgIcon from "../common/ImgIcon";

export default function MenuAppBar() {
  const [alignNavItems] = React.useState({ xs: "center", md: "end" });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <HideOnScroll direction={"down"}>
        <AppBar position="fixed" sx={{backgroundColor: "primary.500"}}>
          <Toolbar>
            <Grid container>
              <Grid item xs={1}>
                <IconButton
                  component={Link}
                  to="/"
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <ImgIcon src={icon} alt="home-icon" height={50} width={50} />
                </IconButton>
              </Grid>
              <Grid item xs={11} sx={{ placeContent: "center" }}>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems={alignNavItems}
                  justifyContent={alignNavItems}
                >
                  <Grid item xs={4}>
                    <Box>
                      <Navigation />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </Box>
  );
}
