import * as React from "react";
import debounce from "lodash/debounce";
import { getContextImages } from "../../../utilities/context-helper";
import { Image } from "../../../models/image";
import Container from "@mui/material/Container";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from "../../common/Modal";

export default function GalleryImageList() {
  const [loading, setLoading] = React.useState(false);
  const screenHeight = window.innerHeight;
  const minScrollLoadHeight = 300;
  const debounceScrollMs = 2000;
  const theme = useTheme();
  const loadedImages = React.useRef<Image[]>([]);
  const contextImages = React.useRef<Image[]>([]);
  const [defaultLoadAmount] = React.useState<number>(5);
  const containerRef = React.useRef<any>();
  const debouncedScroll = React.useRef<any>();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("sm"));
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [modelOpen, setModalOpen] = React.useState<boolean>(false);
  const [modalImg, setModalImg] = React.useState<Image>();

  React.useEffect(() => {
    // Load initial images when the component mounts
    const cxt = require.context(
      "../../../assets/images/gallery",
      false,
      /\.(png|jpe?g|svg)$/
    );

    contextImages.current = getContextImages(cxt);
    addScrollListener();
    debouncedScroll.current = debounce(handleScroll, debounceScrollMs);
    return () => removeScrollListener();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // Load default amount of images once image sources are loaded
    if (contextImages.current.length) {
      load(defaultLoadAmount);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextImages]);

  React.useEffect(() => {
    if (loading) setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedImages]);

  const addScrollListener = () => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", debouncedScroll.current);
    }
  };

  const removeScrollListener = () => {
    if (containerRef.current) {
      containerRef.current.removeEventListener(
        "scroll",
        debouncedScroll.current
      );
    }
  };

  // Handle scroll events
  const handleScroll = () => {
    const container = containerRef.current;

    if (
      container &&
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - minScrollLoadHeight
    ) {
      load(12);
    }
  };

  const load = async (amount: number) => {
    if (
      loading ||
      !contextImages.current.length ||
      loadedImages.current.length === contextImages.current.length
    )
      return;

    setLoading(true);
    amount = Math.floor(amount);

    const iNext = loadedImages.current.length ? loadedImages.current.length : 0;
    const iLast = loadedImages.current.length ? iNext + amount : amount;

    loadedImages.current = [
      ...loadedImages.current,
      ...contextImages.current.slice(iNext, iLast),
    ];
    setLoading(false);
    forceUpdate();
  };

  const onImageClick = (image: Image) => {
    if (matchDownMd) return;

    setModalImg(image);
    setModalOpen(true);
  };

  return (
    <Container
      component={"div"}
      ref={containerRef}
      sx={{
        height: { xs: `${screenHeight}px` },
        overflowY: "auto",
      }}
    >
      <ImageList
        variant={"masonry"}
        cols={matchDownMd ? 1 : 3}
        gap={4}
        sx={{
          width: { xs: "auto", md: "75vw" },
        }}
      >
        <Modal open={modelOpen} setOpen={setModalOpen}>
          <img
            src={modalImg?.src}
            alt={modalImg?.title}
            loading="lazy"
            style={{
              maxHeight: "90vh",
              maxWidth: "90vw",
              overflowY: "hidden",
            }}
          />
        </Modal>
        {loadedImages.current.map((image, index) => (
          <ImageListItem key={`${image.title}-${index}`}>
            <img
              src={image.src}
              alt={image.title}
              loading="lazy"
              onClick={() => onImageClick(image)}
              style={{ cursor: matchDownMd ? "auto" : "pointer" }}
            />
          </ImageListItem>
        ))}
      </ImageList>
      <div style={{ justifyContent: "center", alignItems: "center" }}>
        {loading && <CircularProgress />}
      </div>
    </Container>
  );
}
