import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CardHeader from "@mui/material/CardHeader";
import { Item, ItemSize } from "../../../../models/item";
import Box from "@mui/material/Box";

export type ItemCardProps = {
  item: Item;
  mobileImageOpacity?: number;
};

export default function ItemCard(props: ItemCardProps) {
  const defaultProps: ItemCardProps = {
    mobileImageOpacity: 0.5,
    item: {
      name: "",
      description: "",
      detail: {
        size: ItemSize.SMALL,
        costs: [],
      },
      imageSrc: "",
    },
  };

  props = {
    ...defaultProps,
    ...props,
  };

  const {
    item: {
      description,
      imageSrc,
      detail: { size, costs },
    },
    mobileImageOpacity,
  } = props;

  return (
    <Box
      justifyContent={"center"}
      alignContent={"center"}
      display={"flex"}
    >
      <Card
        sx={{
          backgroundColor: "background.paper",
          maxWidth: { xs: 350, md: 400 },
          minHeight: { xs: 150, md: 200 },
        }}
      >
        <CardHeader
          title={size}
          subheader={description}
          titleTypographyProps={{
            color: "background.paper",
          }}
          sx={{
            backgroundColor: "primary.main",
            background:
              imageSrc 
                ?`linear-gradient(rgba(0, 0, 0, ${mobileImageOpacity}), rgba(0, 0, 0, ${mobileImageOpacity})), url(${imageSrc})`
                : "",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: imageSrc ? "100px" : "auto",
          }}
        />
        <CardContent>
          <Grid container spacing={4} p={4} pt={8}>
            <Grid container item xs={12}>
              {costs.map((cost) => (
                <>
                  <Grid item xs={8}>
                    <Typography variant="body1">{`${cost.description}`}</Typography>
                    {cost.caption && (
                      <Typography variant="caption">{`${cost.caption}`}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="h6"
                      sx={{ float: "right" }}
                    >{`$${cost.price}`}</Typography>
                  </Grid>
                </>
              ))}
              <Grid item xs={12}>
                <Divider
                  sx={(theme) => ({
                    m: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
                    borderColor: "rgba(0, 0, 0, .5)",
                    opacity: 1.0,
                    maxWidth: "150px",
                    ml: "auto",
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  fontWeight={600}
                  sx={{ float: "right" }}
                >{`$${costs.reduce(
                  (sum, current) => sum + current.price,
                  0
                )}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
