import * as React from "react";
import Dialog from "@mui/material/Dialog";

export type ModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: JSX.Element | JSX.Element[];
};

export default function Modal(props: ModalProps) {
  const { open, setOpen, children } = props;

  function handleClose(event: {}, reason: "backdropClick" | "escapeKeyDown") {
    setOpen(!open);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      {children}
    </Dialog>
  );
}
