import * as React from "react";
import Box from "@mui/material/Box";
import ListItemLink from "./ListItemLink";
import { navigationRoutes } from "../../../constants/navigation";
import List from "@mui/material/List";

export default function Navigation() {
  return (
    <Box sx={{ width: "100%" }}>
      <List aria-label="navigation" disablePadding sx={{ display: "flex" }}>
        {navigationRoutes.map((nav) => (
          <ListItemLink key={`nav-list-item-${nav.displayName}`} label={nav.displayName} href={nav.route} />
        ))}
      </List>
    </Box>
  );
}
