export enum ItemSize {
  DEFAULT = "",
  SMALL = "Small",
  MEDIUM = "Medium",
  LARGE = "Large",
  XLARGE = "X-Large"
}

export enum ItemType {
  MONEY_BOUQUET = "Money Bouquet",
  MONEY_BRACELET = "Money Bracelet",
  MONEY_LEI = "Money Lei",
  WRIST_CORSAGE = "Money Wrist Corsage",
  BOUTONNIERE = "Money Boutonniere",
  BALLOON_BACKDROP = "Balloon Backdrop",
  BALLOON_CENTERPIECE = "Balloon Centerpiece",
}

export type ItemCost = {
  description: string;
  caption?: string;
  price: number;
};

export type ItemDetail = {
  size: ItemSize;
  costs: ItemCost[];
};

export type Item = {
  name: string;
  description?: string;
  detail: ItemDetail;
  imageSrc?: string;
};

export type ProductItem = {
  title: string;
  titleImg: string;
  items: Item[];
};
