import * as React from "react";
import { Outlet } from "react-router-dom";
import AppRoot from "./AppRoot";

function App() {
  return (
    <AppRoot>
      <Outlet />
    </AppRoot>
  );
}

export default App;
