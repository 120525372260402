import Icon from "@mui/material/Icon";
import * as React from "react";

export type ImgIconProps = {
    src: string;
    alt: string;
    height?: number;
    width?: number;
}

export default function ImgIcon(props: ImgIconProps) {
    const { alt, height, width } = props;
    return (
      <Icon sx={{ height: height, width: width }}>
        <img {...props} alt={alt} />
      </Icon>
    );
  };
