import * as React from "react";
import CenterGridContainer from "../layout/CenterGridContainer";
import Grid from "@mui/material/Grid";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import { errorPageText } from "../../data/page-text";
import { Link } from "react-router-dom";
import { useRouteError } from "react-router-dom";
import AppRoot from "./AppRoot";
import Typography from "@mui/material/Typography";

export default function AppError() {
  const error = useRouteError() as Response;

  return (
    <AppRoot>
      <CenterGridContainer spacing={2} pt={8}>
        <Grid item xs={12}>
          <ErrorIcon
            color="primary"
            sx={{
              fontSize: 300,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{errorPageText.errorAppMessage}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {Boolean(error?.status) && `${error?.status}: `}
            {error?.statusText}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Link to={"/"}>
            <Typography variant="h6">
              {errorPageText.errorAppActionMessage}
            </Typography>
          </Link>
        </Grid>
      </CenterGridContainer>
    </AppRoot>
  );
}
