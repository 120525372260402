import * as React from "react";
import CenterGridContainer from "../../layout/CenterGridContainer";
import Grid from "@mui/material/Grid";
import GalleryImageList from "./GalleryImageList";

export default function Gallery() {
  return (
    <CenterGridContainer>
      <Grid item>
        <GalleryImageList />
      </Grid>
    </CenterGridContainer>
  );
}
