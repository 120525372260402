import * as React from "react";
import ItemCard from "./item-card/ItemCard";
import Grid from "@mui/material/Grid";
import CenterGridContainer from "../../layout/CenterGridContainer";
import { products } from "../../../data/products";
import { ProductItem } from "../../../models/item";
import Typography from "@mui/material/Typography";
import ItemCardImage from "./item-card/ItemCardImage";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";

export default function Pricing() {
  const matchDownMd = useMediaQuery((theme) =>
    (theme as Theme).breakpoints.down("sm")
  );

  const ProductTitle = (item: ProductItem) => {
    return (
      <Box maxWidth={"min-content"} sx={{ float: "right" }}>
        <Typography variant={"h3"}>{item.title}</Typography>
      </Box>
    );
  };

  const MdProductTitle = (props: { item: ProductItem; i: number }) => {
    const { item, i } = props;

    return (
      <>
        <Grid item xs={6} m={"auto"} zIndex={1}>
          <ProductTitle {...item} />
        </Grid>
        <Grid item xs={6} m={"auto"}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            pl={"100px"}
            width={0}
            position={"relative"}
            sx={{
              transformOrigin: "center",
              rotate: `${i % 2 ? "-" : ""}12deg`,
            }}
          >
            <ItemCardImage
              imageSize={175}
              src={item.titleImg}
              alt={`${item.title}-title-image`}
            />
          </Box>
        </Grid>
      </>
    );
  };

  const SmProductTitle = (props: ProductItem) => {
    return (
      <Grid item m={"auto"}>
        <ProductTitle {...props} />
      </Grid>
    );
  };

  return (
    <CenterGridContainer pt={8}>
      {products?.map((x, i) => (
        <>
          <Grid container item direction={"row"} xs={12}>
            <>
              {(!matchDownMd && <MdProductTitle item={x} i={i} />) || (
                <SmProductTitle {...x} />
              )}
            </>
          </Grid>
          <Grid
            container
            item
            spacing={1}
            direction={"row"}
            justifyContent={"center"}
            mt={{ xs: 2, sm: 4 }}
            px={10}
          >
            {x.items.map((product, i) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                maxWidth={!matchDownMd ? 200 : "auto"}
              >
                <ItemCard item={product} />
              </Grid>
            ))}
          </Grid>
        </>
      ))}
    </CenterGridContainer>
  );
}
