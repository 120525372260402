import * as React from "react";
import Box from "@mui/material/Box";
import CenterGridContainer from "./CenterGridContainer";
import { Typography } from "@mui/material";

export default function Footer() {
  return (
    <Box
      component={"footer"}
      position={"absolute"}
      sx={{
        minHeight: "20%",
        width: "100%",
        backgroundColor: "primary.main",
      }}
    >
      <CenterGridContainer>
        <Typography variant="caption" paddingTop={10}>
          &copy; Bucks & Balloons LLC {new Date().getFullYear()}
        </Typography>
      </CenterGridContainer>
    </Box>
  );
}
