export const pageText = {
  homeWelcomeText:
    "Bucks & Balloons designs hand-crafted, custom-made money accessories or balloons so you can celebrate with style! From balloon displays to money bouquets, we've got you covered!",
  homeUsagesText:
    "Great for birthdays, anniversaries, graduations, and weddings!",
  homeCallToActionText: "Order yours today!",
};

export const errorPageText = {
  errorAppMessage: "Uh oh, something went wrong...",
  errorAppActionMessage: "Back to home."
}