import { ItemSize, ItemType, ProductItem } from "../models/item";

import moneyBouquetTitleImg from "../assets/images/gallery/20240607_090055.jpg";
import moneyBouquetImgSm from "../assets/images/gallery/20240611_010516.jpg";
import moneyBouquetImgMd from "../assets/images/gallery/Money_Bouquet_3.jpg";
import moneyBouquetImgLg from "../assets/images/gallery/20240607_090027.jpg";
import moneyBouquetImgXl from "../assets/images/gallery/1000002108.jpg";

import moneyBraceletTitleImg from "../assets/images/gallery/Money_Bracerlet_2.jpg";
import moneyBraceletImgSm from "../assets/images/gallery/Money_Bracelet_2.jpg";
import moneyBraceletImgMd from "../assets/images/gallery/money_bracelet_1.jpg";

import moneyLeiTitleImg from "../assets/images/gallery/Money_Lei_2.jpg";

import moneyCorsTitleImg from "../assets/images/gallery/Money_Corsage_1.jpg";

import moneyBtnrTitleImg from "../assets/images/gallery/Money_Boutonniere_1.jpg";

import balloonBkTitleImg from "../assets/images/gallery/Balloon_Celebration_Display_1.jpg";
import balloonBkImg1 from "../assets/images/gallery/Balloon_4th_of_July_Display_Banner.jpg";

import balloonCtrTitleImg from "../assets/images/gallery/Balloon_Christmas_Door_Display_1.jpg";
import balloonCtrImg1 from "../assets/images/gallery/Balloon_Thanksgiving_Display_1.jpg";



export const products: ProductItem[] = [
  {
    title: ItemType.MONEY_BOUQUET,
    titleImg: moneyBouquetTitleImg,
    items: [
      {
        name: ItemType.MONEY_BOUQUET,
        imageSrc: moneyBouquetImgSm,
        detail: {
          size: ItemSize.SMALL,
          costs: [
            {
              description: ItemType.MONEY_BOUQUET,
              price: 25,
            },
            {
              description: "Labor",
              price: 40,
            },
          ],
        },
      },
      {
        name: ItemType.MONEY_BOUQUET,
        imageSrc: moneyBouquetImgMd,
        detail: {
          size: ItemSize.MEDIUM,
          costs: [
            {
              description: ItemType.MONEY_BOUQUET,
              price: 30,
            },
            {
              description: "Labor",
              price: 40,
            },
          ],
        },
      },
      {
        name: ItemType.MONEY_BOUQUET,
        imageSrc: moneyBouquetImgLg,
        detail: {
          size: ItemSize.LARGE,
          costs: [
            {
              description: ItemType.MONEY_BOUQUET,
              price: 50,
            },
            {
              description: "Labor",
              price: 50,
            },
          ],
        },
      },
      {
        name: ItemType.MONEY_BOUQUET,
        imageSrc: moneyBouquetImgXl,
        detail: {
          size: ItemSize.XLARGE,
          costs: [
            {
              description: ItemType.MONEY_BOUQUET,
              price: 100,
            },
            {
              description: "Labor",
              price: 75,
            },
          ],
        },
      },
    ],
  },
  {
    title: ItemType.MONEY_BRACELET,
    titleImg: moneyBraceletTitleImg,
    items: [
      {
        name: ItemType.MONEY_BRACELET,
        imageSrc: moneyBraceletImgSm,
        detail: {
          size: ItemSize.SMALL,
          costs: [
            {
              description: ItemType.MONEY_BRACELET,
              price: 7,
            },
            {
              description: "Labor",
              price: 21,
            },
          ],
        },
      },
      {
        name: ItemType.MONEY_BRACELET,
        imageSrc: moneyBraceletImgMd,
        detail: {
          size: ItemSize.MEDIUM,
          costs: [
            {
              description: ItemType.MONEY_BRACELET,
              price: 14,
            },
            {
              description: "Labor",
              price: 21,
            },
          ],
        },
      },
    ],
  },
  {
    title: ItemType.MONEY_LEI,
    titleImg: moneyLeiTitleImg,
    items: [
      {
        name: ItemType.MONEY_LEI,
        detail: {
          size: ItemSize.SMALL,
          costs: [
            {
              description: ItemType.MONEY_LEI,
              price: 20,
            },
            {
              description: "Labor",
              price: 35,
            },
          ],
        },
      },
      {
        name: ItemType.MONEY_LEI,
        detail: {
          size: ItemSize.MEDIUM,
          costs: [
            {
              description: ItemType.MONEY_LEI,
              price: 40,
            },
            {
              description: "Labor",
              price: 35,
            },
          ],
        },
      },
    ],
  },
  {
    title: ItemType.WRIST_CORSAGE,
    titleImg: moneyCorsTitleImg,
    items: [
      {
        name: ItemType.WRIST_CORSAGE,
        detail: {
          size: ItemSize.DEFAULT,
          costs: [
            {
              description: ItemType.WRIST_CORSAGE,
              price: 3,
            },
            {
              description: "Labor",
              price: 22,
            },
          ],
        },
      },
    ],
  },
  {
    title: ItemType.BOUTONNIERE,
    titleImg: moneyBtnrTitleImg,
    items: [
      {
        name: ItemType.BOUTONNIERE,
        detail: {
          size: ItemSize.DEFAULT,
          costs: [
            {
              description: ItemType.BOUTONNIERE,
              price: 1,
            },
            {
              description: "Labor",
              price: 19,
            },
          ],
        },
      },
    ],
  },
  {
    title: ItemType.BALLOON_BACKDROP,
    titleImg: balloonBkTitleImg,
    items: [
      {
        name: ItemType.BALLOON_BACKDROP,
        imageSrc: balloonBkImg1,
        detail: {
          size: ItemSize.DEFAULT,
          costs: [
            {
              description: ItemType.BALLOON_BACKDROP,
              caption: "100 Balloons Max",
              price: 100,
            },
          ],
        },
      },
    ],
  },
  {
    title: ItemType.BALLOON_CENTERPIECE,
    titleImg: balloonCtrTitleImg,
    items: [
      {
        name: ItemType.BALLOON_CENTERPIECE,
        imageSrc: balloonCtrImg1,
        detail: {
          size: ItemSize.DEFAULT,
          costs: [
            {
              description: ItemType.BALLOON_CENTERPIECE,
              price: 40,
            },
          ],
        },
      },
    ],
  },
];