import * as React from "react";
import MenuAppBar from "../app-bar/AppBar";
import Grid from "@mui/material/Grid";
import Footer from "../layout/Footer";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../style/theme";

export default function AppRoot(props: {
  children: JSX.Element | JSX.Element[];
}) {
  return (
    <ThemeProvider theme={theme}>
      <MenuAppBar />
      <Grid
        container
        spacing={0}
        m={0}
        p={0}
        sx={{ backgroundColor: "background.default" }}
      >
        <Grid item xs={12} pt={{ xs: 15 }}>
          {props.children}
        </Grid>
        <Grid item xs={12} mt={25}>
          <Footer />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
