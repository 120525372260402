import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export type IconInfoProps = {
    icon: JSX.Element;
    label: string;
    gridItem?: boolean;
};

export default function IconInfo(props: IconInfoProps) {
    const { icon, label, gridItem } = props;
    return (
      <Grid container item={gridItem}>
        <Grid item xs={2} justifyContent={"start"} display={"flex"}>
          {icon}
        </Grid>
        <Grid item xs={10} justifyContent={"start"} alignContent={"center"}>
          <Typography>{label}</Typography>
        </Grid>
      </Grid>
    );
  };