import Grid, { GridProps } from "@mui/material/Grid";
import * as React from "react";

export type CenterContainerProps = Omit<
  GridProps,
  "container" | "alignItems" | "justifyContent"
>;

export default function CenterGridContainer(props: CenterContainerProps) {
  const { children } = props;

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      sx={{placeItems: "center"}}
      {...props}
    >
      {children}
    </Grid>
  );
}
