import green from "@mui/material/colors/green";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: "Cormorant, serif",
      fontSize: 18,
      h2: { fontWeight: 600 },
      fontWeightRegular: 500,
      fontWeightBold: 700,
      fontWeightMedium: 600,
    },
    spacing: 4,
    palette: {
      primary: {
        main: "#ec4d4d",
        "500": "#a13535",
      },
      secondary: {
        main: "#ff8b8b",
      },
      background: {
        default: "#fff1f1",
        paper: "#ffffff",
      },
      text: {
        secondary: green[300],
      },
    },
  })
);

// custom spacing
theme.spacing(2);

export default theme;
