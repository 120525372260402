import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { pageText } from "../../../../data/page-text";
import ContactCard from "./ContactCard";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import CenterGridContainer from "../../../layout/CenterGridContainer";

export default function Contact() {
  return (
    <CenterGridContainer>
      <Grid item xs={12}>
        <Typography variant={"h4"}>
          {pageText.homeCallToActionText}
          <br />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ContactCard
          title={"Veronica Minter, Designer"}
          info={[
            {
              icon: <EmailIcon />,
              label: "bucksnballoons@yahoo.com",
            },
            {
              icon: <PhoneIcon />,
              label: "440-825-2346",
            },
          ]}
        />
      </Grid>
    </CenterGridContainer>
  );
}
