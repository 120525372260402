import { Box } from "@mui/material";
import * as React from "react";

export type ItemCardImageProps = {
  src: string;
  imageSize: number;
  alt?: string;
};

export default function ItemCardImage(props: ItemCardImageProps) {
  const { src, imageSize, alt } = props;

  return (
    <Box
      component={"div"}
      height={imageSize}
      width={imageSize}
      sx={{
        border: "1rem solid white",
        boxShadow: "3px 5px 5px black"
      }}
    >
      <Box
        component={"img"}
        src={src}
        alt={alt}
        height={imageSize}
        width={imageSize}
        m={"0 auto"}
        sx={{
          background: "white",
          border: "3px solid black",
        }}
      />
    </Box>
  );
}
