import * as React from "react";
import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";

type HideOnScrollProps = {
  children: JSX.Element;
  direction?: "down" | "left" | "right" | "up" | undefined;
};

export default function HideOnScroll(props: HideOnScrollProps) {
  const { children, direction } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction={direction} in={!trigger}>
      {children}
    </Slide>
  );
}
