import * as React from "react";
import Box from "@mui/material/Box";
import Grid, { GridProps } from "@mui/material/Grid";
import { grey } from "@mui/material/colors";
import CenterGridContainer from "../../layout/CenterGridContainer";
import About from "./About";
import HomeBannerImage from "./HomeBannerImage";
import Contact from "./contact/Contact";

function Home() {
  const HomeGrid = (props: GridProps) => {
    return (
      <Grid item xs={12} m={{ xs: 4, md: 20 }} marginTop={{ md: 8 }}>
        {props.children}
      </Grid>
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Box
          height={{
            xs: "60vh",
            md: "45vh",
          }}
          sx={{ backgroundColor: grey[800] }}
        >
          <CenterGridContainer height={"100%"}>
            <HomeBannerImage />
          </CenterGridContainer>
        </Box>
      </Grid>
      <HomeGrid>
        <About />
      </HomeGrid>
      <HomeGrid>
        <Contact />
      </HomeGrid>
    </>
  );
}

export default Home;
