import Page from "../models/page";

export const navigationRoutes: Page[] = [
  {
    displayName: "Pricing",
    route: "/pricing",
  },
  {
    displayName: "Gallery",
    route: "/gallery",
  },
];
