import * as React from "react";
import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

export type ListItemLinkProps = {
  label: string;
  href: string;
  selected?: boolean;
};

export default function LinkItemLink(props: ListItemLinkProps) {
  const { label, href, selected } = props;
  const navigate = useNavigate();
  return (
    <ListItemButton
      id={`link-tab-${label}`}
      onClick={() => navigate(href)}
      disableRipple
      disableTouchRipple
      aria-current={selected && "page"}
      aria-controls={`simple-tabpanel-${label}`}
    >
      <ListItemText
        primary={label}
      />
    </ListItemButton>
  );
}
