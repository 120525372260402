import * as React from "react";
import BannerImage from "../../../assets/images/gallery/Money_Bouquet_5.jpg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled } from "@mui/material";

export default function HomeBannerImage() {
  const bgDarkness = 0.5;
  const bgContrast = 1.1;

  const HeaderText = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontWeight: 600,
    placeItems: "center",
  }));

  return (
    <Box
      component={"div"}
      height={"100%"}
      width={"100%"}
      color={"primary"}
      sx={({ breakpoints }) => ({
        background: `linear-gradient(rgba(0, 0, 0, ${bgDarkness}), rgba(0, 0, 0, ${bgDarkness})), url(${BannerImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: `contrast(${bgContrast})`,
        backgroundColor: "gray",
        backgroundBlendMode: "luminosity",
        [breakpoints.up("xs")]: {
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          alignContent: "center",
          fontVariant: "small-caps",
        },
      })}
    >
      <Grid container>
        <Grid item xs={12}>
          <HeaderText variant="h2" color="primary" component={"span"}>
            Bucks
          </HeaderText>
          <HeaderText variant="h2" color={"text.secondary"} component={"span"}>
            {" & "}
          </HeaderText>
          <HeaderText variant="h2" color="primary" component={"span"}>
            Balloons
          </HeaderText>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="secondary" fontWeight={600}>
            Money and Balloon Accessories
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
