import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconInfo, { IconInfoProps } from "../../../common/InfoIcon";
import CardHeader from "@mui/material/CardHeader";

export type ContactCardProps = {
  title: string;
  info: Omit<IconInfoProps, "gridItem">[];
};

export default function ContactCard(props: ContactCardProps) {
  const { title, info } = props;

  return (
    <Card sx={{ maxWidth: 400 }}>
      <CardHeader
        sx={{ backgroundColor: "secondary.main" }}
        title={title}
      />
      <CardContent key="contact-card-content" sx={{ backgroundColor: "primary.main" }}>
        <Grid container spacing={2}>
          {info.map((info, i) => (
            <IconInfo key={`contact-card-info-${i}`} {...info} gridItem />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
