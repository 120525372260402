import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { pageText } from "../../../data/page-text";

export default function About() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>
          {pageText.homeWelcomeText}
          <br/>
          <br/>
          {pageText.homeUsagesText}
          <br/>
        </Typography>
      </Grid>
    </Grid>
  );
}
